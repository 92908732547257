/**
* Generated automatically at built-time (2025-04-10T12:45:52.988Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "smeg-spis",templateKey: "sites/96-79572eae-a120-42ac-833a-f3b00424152e"};